import React from 'react'

import {
  formatPhoneNumber,
  getTrackingData,
} from 'helpers'

import { NavLink } from 'react-router-dom'

import { SiteContext } from 'context/site-context'

import WebP from 'components/atoms/WebP'

import StickyCTA from 'components/atoms/StickyCTA'

import LogoIcon from 'images/logo-white.png'
import LogoIconWebP from 'images/logo-white.webp'

import './index.scss'

const Footer = () => {
  const [ site, setSite ] = React.useContext(SiteContext)

  return (
    <footer>
      <div className="container">
        <div className="logo-container">
          <NavLink to={`${process.env.PUBLIC_URL}/`} className="main-logo" onClick={ () => setSite({ ...site, showHeader: false }) }>
            <WebP src={LogoIconWebP} fallback={LogoIcon} alt="Locksmith Directory" />
          </NavLink>
        </div>
        <div className="copyright-container">
          &copy; {new Date().getFullYear()} {site.domain}. All rights reserved.
        </div>
        <a className="phone-trigger" href={`tel:${site.phone}`} data-delegate="All Rights Reserved" data-tracking-data={getTrackingData()}>{formatPhoneNumber(site.phone)}</a>

        <div className="nav-panel">
          <h3>Our Services</h3>
          <NavLink to="residential">Residential</NavLink>
          <NavLink to="commercial">Commercial</NavLink>
          <NavLink to="automotive">Automotive</NavLink>
          <NavLink to="new-home">New Home</NavLink>
        </div>
        <div className="support-container">Support: 1-877-913-5622</div>
        <div className="address-container">
          Felix Calls, LLC (owned and operated by eLocal USA LLC)<br />
          1010 Spring Mill Ave, Suite 200, Conshohocken PA 19428
        </div>
        <div className="nav-bar">
          <a href="https://www.elocal.com/information#terms" data-delegate="Footer">Terms and Conditions</a>
          <a href="https://www.elocal.com/information#privacy" data-delegate="Footer">Privacy Policy</a>
        </div>
      </div>
      <StickyCTA />
    </footer>
  )
}

export default Footer
