import React from 'react'

import { SiteContext } from 'context/site-context'

import {
  formatPhoneNumber,
  isWebpSupported
} from 'helpers'

import PhoneCTAButton from 'components/atoms/PhoneCTAButton'

import { ReactComponent as CarIcon } from 'svgs/car.svg'
import { ReactComponent as HomeIcon } from 'svgs/home.svg'
import { ReactComponent as KeyIcon } from 'svgs/key.svg'

import BackgroundImage from 'images/original-hero.png'
import BackgroundImageWebP from 'images/original-hero.webp'

import PhoneIcon from 'images/phone.png'
import PhoneIconWebP from 'images/phone.webp'
import Logo from 'images/logo.png'
import LogoWebP from 'images/logo.webp'
import SameDayIcon from 'images/calendar.png'
import SameDayIconWebP from 'images/calendar.webp'
import CreditCardsIcon from 'images/creditcards.png'
import CreditCardsIconWebP from 'images/creditcards.webp'

import WebP from 'components/atoms/WebP'

import './index.scss'

const Hero = () => {
  const [ site ] = React.useContext(SiteContext)

  const getHeadline = () => {
    const headline = new URLSearchParams(window.location.search).get('h1')
    return headline ? decodeURIComponent(headline) : '24-Hr Locksmith Dispatch'
  }

  return <div className="hero-emergency-container" style={{
      background: `url(${isWebpSupported() ? BackgroundImageWebP : BackgroundImage}) center center no-repeat`,
      backgroundSize: 'cover',
    }}>
    <div className="container">
      <div className="logo-white-container"><span><img src={Logo} alt="Locksmith Directory" /></span></div>
      <div className="actions-container">
        <PhoneCTAButton delegate="Header" />
      </div>
      <h1>{getHeadline()}</h1>
      <h2>Fast & Reliable!</h2>
      <div className="buttons-container">
        <a className="button-trigger" href={`tel:${site.phone}`} data-delegate="Hero"><CarIcon />Call for Car Lockout</a>
        <a className="button-trigger" href={`tel:${site.phone}`} data-delegate="Hero"><HomeIcon />Call for Home Lockout</a>
        <a className="button-trigger" href={`tel:${site.phone}`} data-delegate="Hero"><KeyIcon />Call for a Key Replacement</a>
      </div>
      <div className="icons-container">
        <div className="icon-container">
          <a href={`tel:${site.phone}`} data-delegate="Hero">
            <WebP src={SameDayIconWebP} fallback={SameDayIcon} alt="Same Day Service" />
            <div className="content">
              Same Day<br /><strong>Service</strong>
            </div>
          </a>
        </div>
        <div className="icon-container">
          <a href={`tel:${site.phone}`} data-delegate="Hero">
            <WebP src={CreditCardsIconWebP} fallback={CreditCardsIcon} alt="Credit Cards Accepted" />
            <div className="content">
              Credit Cards<br /><strong>Accepted</strong>
            </div>
          </a>
        </div>
        <div className="icon-container">
          <a href={`tel:${site.phone}`} data-delegate="Hero">
            <WebP src={PhoneIconWebP} fallback={PhoneIcon} alt="Call Us 24/7" />
            <div className="content">
              Call Us 24/7<br /><strong>{formatPhoneNumber(site.phone)}</strong>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
}

export default Hero
