import React from 'react'

import cn from 'classnames'

import { SiteContext } from 'context/site-context'

import About from 'components/sections/About'
import Footer from 'components/sections/Footer'
import Hero from 'components/sections/Hero'
import PriceGuide from 'components/atoms/PriceGuide'
import Services from 'components/sections/Services'

import './index.scss'

const Landing = () => {
  const [ site ] = React.useContext(SiteContext)

  return <div className={cn('page-wrapper', {isLoadingSiteData: site.isLoadingSiteData})}>
    <Hero />
    <PriceGuide />
    <About />
    <Services />
    <Footer />
  </div>
}

export default Landing
