import React from 'react'
import PropTypes from 'prop-types'

import { SiteContext } from 'context/site-context'

import { NavLink } from 'react-router-dom'

import cn from 'classnames'

import PhoneCTAButton from 'components/atoms/PhoneCTAButton'

import LogoIcon from 'images/logo-black.png'

import './index.scss'

const Header = props => {
  const [ site, setSite ] = React.useContext(SiteContext)

  return (
    <header className={cn( props.type, { visible: props.showHeader, animate: props.animate })}>
      <div className="container">
        <div className="main-logo-container">
          <NavLink to={`${process.env.PUBLIC_URL}/`} className="main-logo" onClick={ () => setSite({ ...site, showHeader: false }) }>
            <img src={LogoIcon} alt="Locksmith Directory" />
          </NavLink>
        </div>
        <div className="actions-container">
          <PhoneCTAButton delegate="Header" />
          {/*
          <NavLink to={`${process.env.PUBLIC_URL}/`}
            className={cn('button-trigger', 'alt', 'inverse', 'non-emergency-link', 'toggle-trigger', {'override-show': site.viewOverride === 'emergency', 'override-hide': site.viewOverride === 'non-emergency'}) }
            onClick={ () => {
              setSite({ ...site, viewOverride: 'non-emergency' })
              window.scrollTo(0, 0)
            } }>Non-Emergency<br />Services</NavLink>
          <NavLink to={`${process.env.PUBLIC_URL}/`}
            className={cn('button-trigger', 'alt', 'inverse', 'emergency-link', 'toggle-trigger', {'override-show': site.viewOverride === 'non-emergency', 'override-hide': site.viewOverride === 'emergency'}) }
            onClick={ () => {
              setSite({ ...site, viewOverride: 'emergency' })
              window.scrollTo(0, 0)
            } }>Emergency<br/><span className="desktop">Lockout </span>Services</NavLink>
            */}
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  type: PropTypes.string,
  isEmergency: PropTypes.bool,
}

export default Header
