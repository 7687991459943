module.exports = {
  categoryId: '173',
  category: 'home_services',
  categoryPlural: 'Locksmiths',
  domain: 'LocksmithDirectory.com',
  partner_campaign_ids: ['40039'],
  phone: '888-403-5690',
  prodUrl: 'https://find.locksmithdirectory.com/',
  siteName: 'local.locksmithdirectory',
  stageUrl: 'https://find.stage.locksmithdirectory.com/',
  templateId: 'RT46',
  title: 'Locksmiths - Locksmith Services and Professional Locksmiths,'
}
