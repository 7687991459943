import React from 'react'

import cn from 'classnames'

import { SiteContext } from 'context/site-context'

import Header from 'components/sections/Header'
import HeroService from 'components/sections/HeroService'
import AutomotiveContent from 'components/sections/AutomotiveContent'
import PriceGuide from 'components/atoms/PriceGuide'
import Why from 'components/sections/Why'
import SimpleCTA from 'components/sections/SimpleCTA'
import Footer from 'components/sections/Footer'

import BackgroundImage from 'images/automotive-locksmith-services-hero.jpg'

import './index.scss'

const priceList = [
  { label: 'Service Fee	',  category: 'Minimum Charge (Included As Part The Final Service Charge)	', price: '$29 (fixed)' },
  { label: 'All Lockout Services',  category: 'Commercial, Residential & Automotive (Car Lockout)', price: '$48 - $230' },
  { label: 'Key Extraction', category: 'Commercial & Residential', price: '$48 - $130' },
  { label: 'New Vehicle Key', category: 'Car, Truck', price: '$129 - $330' },
  { label: 'Ignition Change', category: 'Any Vehicle', price: '$129 - $330' },
  { label: 'Locks Change or Rekey', category: 'Any Vehicle', price: '$129	-	$330' },
  { label: 'Program Transponder', category: '(Key with a chip)', price: '$129 - $230' },
]

const Automotive = () => {
  const [ site, setSite ] = React.useContext(SiteContext)

  React.useEffect(() => {
    console.log('yoinks')
    const updateHeaderState = () => {
      setSite({
        ...site,
        showHeader: window.scrollY > site.minHeaderScrollDistance,
      })
    }
    window.addEventListener('scroll', updateHeaderState)
    return () => {
      window.removeEventListener('scroll', updateHeaderState)
    }
  }, [site, setSite])
  return <div className={cn('page-wrapper animate', {isLoadingSiteData: site.isLoadingSiteData})}>
    <Header showHeader={site.showHeader} animate={true} />
    <HeroService backgroundImage={BackgroundImage} headline="Automotive Locksmiths"/>
    <AutomotiveContent />
    <div className="price-guide-wrapper">
      <PriceGuide priceList={priceList} />
    </div>
    <Why />
    <SimpleCTA />
    <Footer />
  </div>
}

export default Automotive
