import React from 'react'
import PropTypes from 'prop-types'

import { LazyLoadImage } from 'react-lazy-load-image-component'

import { isWebpSupported } from 'helpers'

const WebP = ({
  src,
  fallback,
  type = 'image/webp',
  ...delegated
}) => {
  return isWebpSupported() ? <LazyLoadImage src={src} {...delegated} /> : <LazyLoadImage src={fallback} {...delegated} />
}

export default WebP
