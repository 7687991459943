import React from 'react'
import PropTypes from 'prop-types'

import { SiteContext } from 'context/site-context'

import WebP from 'components/atoms/WebP'

import PriceGuideIcon from 'images/priceguide-calc.png'
import PriceGuideIconWebP from 'images/priceguide-calc.webp'

import './index.scss'

const priceList = [
  { label: 'Service Fee	',  category: 'Fixed Fee (Included as part of the final service charge)', price: '$29 (fixed fee)' },
  { label: 'All Lockout Services',  category: 'Commercial, Residential & Automotive (Car Lockout)', price: '$48 - $230' },
  { label: 'Locks Re-Key', category: 'Commercial & Residential', price: '$48 - $300' },
  { label: 'Lock Repair', category: 'Commercial & Residential', price: '$48 - $180' },
  { label: 'Key Extraction', category: 'Commercial & Residential', price: '$48 - $130' },
  { label: 'Lock Change', category: 'Residential, Commercial - For Basic Locks', price: '$54 - $230' },
  { label: 'New Lock Installation', category: '(Door without a hole for a lock)', price: '$114 - $230' },
  { label: 'Unlock Safe', category: '(Damage may occur to the safe)', price: '$114 - $330' },
  { label: 'New Vehicle Key', category: 'Car, Truck', price: '$129 - $330' },
  { label: 'Ignition Change', category: 'Any Vehicle', price: '$129 - $330' },
  { label: 'Locks Change or Rekey', category: 'Any Vehicle', price: '$129	-	$330' },
  { label: 'Program Transponder', category: '(Key with a chip)', price: '$129 - $230' },
  { label: 'Key Extraction', category: 'Automotive', price: '$129 - $230' },
  { label: 'Lock Change', category: 'Push bars / Combination Lock / High Security Locks', price: '$149	-	$530' },
  { label: 'Access Control System / Buzzer System', price: '$379 - $1,230' },
]

const PriceGuide = () => {
  const [ site ] = React.useContext(SiteContext)

  const trackingData = {
    page: window.location.href,
    category: 'OPTION',
  }

  return (
    <React.Fragment>
      {
        window.location.href.indexOf('find.') === -1 &&
          <div className="price-guide-container">
            <div className="container">
              <ul>
                <li className="header">
                  <div className="icon-container">
                    <WebP src={PriceGuideIconWebP} fallback={PriceGuideIcon} alt="Price Guide" />
                    <div className="content">
                      Price <strong>Guide</strong>
                    </div>
                  </div>
                  <div className="title"><span>min to max</span> <span>price</span></div>
                </li>
                {priceList.map( (item, i) => <li key={i}><a href={`tel:${site.phone}`} data-delegate="Price Guide" data-tracking-data={JSON.stringify({ ...trackingData, 'cost-range': item.price })}><label>{item.label}</label><span className="category">{item.category}</span><span className="price">{item.price}</span></a></li>)}
                <li className="disclaimer"><a href={`tel:${site.phone}`} data-delegate="Price Guide" data-tracking-data="">(*) Prices Above Include Base $29 Trip Charge</a></li>
                <li className="disclaimer"><a href={`tel:${site.phone}`} data-delegate="Price Guide" data-tracking-data="">(**) All prices are for basic service if something else will need to be done on site, tech will provide the prices.</a></li>
                <li className="disclaimer"><a href={`tel:${site.phone}`} data-delegate="Price Guide" data-tracking-data="">(***) Cost is a minimum estimate for the service. The final cost can vary. It will depend on the security level of the lock in addition to labor charges.</a></li>
                <li className="disclaimer"><a href={`tel:${site.phone}`} data-delegate="Price Guide" data-tracking-data="">(****) Cost is a minimum estimate for the service. Every key or ignition is differently built and cut, so the final cost can vary. It will depend on the cut of the key and programming cost if required the tech will provide the prices on site before he will start the job.</a></li>
                <li className="disclaimer"><a href={`tel:${site.phone}`} data-delegate="Price Guide" data-tracking-data="">(*****) Price guide based on average national pricing</a></li>
                <li className="disclaimer"><a href={`tel:${site.phone}`} data-delegate="Price Guide" data-tracking-data="">(******) Cost does not include applicable taxes</a></li>
              </ul>
            </div>
          </div>
      }
    </React.Fragment>
  )
}

export default PriceGuide
