import React from 'react'

import { SiteContext } from 'context/site-context'

import { NavLink } from 'react-router-dom'

import { ReactComponent as ArrowIcon } from 'svgs/arrow.svg'

import './index.scss'

const NavPanel = props => {
  const [ site, setSite ] = React.useContext(SiteContext)

  return (
    <div className="nav-panel-container">
      <NavLink
        to={`${process.env.PUBLIC_URL}/residential`}
        activeClassName="active"
        data-tracking-data={ JSON.stringify({ label: 'Side Bar - Residential Locksmiths' })}
        onClick={ () => setSite({
          ...site,
          showHeader: false,
          viewOverride: 'non-emergency',
        }) }>Residential Locksmiths <ArrowIcon /></NavLink>
      <NavLink
        to={`${process.env.PUBLIC_URL}/commercial`}
        activeClassName="active"
        data-tracking-data={ JSON.stringify({ label: 'Side Bar - Commercial Locksmiths'})}
        onClick={ () => setSite({
          ...site,
          showHeader: false,
          viewOverride: 'non-emergency',
        }) }>Commercial Locksmiths <ArrowIcon /></NavLink>
      <NavLink
        to={`${process.env.PUBLIC_URL}/automotive`}
        activeClassName="active"
        data-tracking-data={ JSON.stringify({ label: 'Side Bar - Automotive Locksmiths'})}
        onClick={ () => setSite({
          ...site,
          showHeader: false,
          viewOverride: 'non-emergency',
        }) }>Automotive Locksmiths <ArrowIcon /></NavLink>
      <NavLink
        to={`${process.env.PUBLIC_URL}/new-home`}
        activeClassName="active"
        data-tracking-data={ JSON.stringify({ label: 'Side Bar - New Home Owner Services'})}
        onClick={ () => setSite({
          ...site,
          showHeader: false,
          viewOverride: 'non-emergency',
        }) }>New Home Owner Services <ArrowIcon /></NavLink>
    </div>
  )
}

export default NavPanel
