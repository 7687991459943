import React from 'react'
import PropTypes from 'prop-types'

import {
  formatPhoneNumber,
  getTrackingData,
} from 'helpers'

import { SiteContext } from 'context/site-context'

import WebP from 'components/atoms/WebP'

import PhoneIcon from 'images/phone.png'
import PhoneIconWebP from 'images/phone.webp'

import './index.scss'

const PhoneCTAButton = props => {
  const [ site ] = React.useContext(SiteContext)

  return <a className="phone-trigger-container" href={`tel:${site.phone}`} data-delegate={props.delegate} data-tracking-data={getTrackingData()}>
    <WebP src={PhoneIconWebP} fallback={PhoneIcon} alt="Call Us 24/7" />
    <label>Call us 24/7</label>
    <div>{formatPhoneNumber(site.phone)}</div>
  </a>
}

PhoneCTAButton.propTypes = {
  delegate: PropTypes.string,
}

export default PhoneCTAButton
