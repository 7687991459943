import React from 'react'

import {
  formatPhoneNumber,
  getGeoFromLocalStorage,
} from 'helpers'

import metadata from './metadata'

const PARTNER_CAMPAIGN_ID_MAPPINGS = {
  48801: {
    phone: '888-493-8929', 
    gtag_code: 'MBwSCPiY4fsDELrklusD',
  },
  48800: {
    phone: '888-493-9312',
    gtag_code: 'hAxTCIOmp6cBELrklusD',
  },
  40040: {
    phone: '888-497-9103',
    gtag_code: 'hAxTCIOmp6cBELrklusD',
  },
  40036: {
    phone: '877-325-3091',
    gtag_code: 'hAxTCIOmp6cBELrklusD',
  },
  40038: {
    phone: '877-959-5122',
    gtag_code: 'hAxTCIOmp6cBELrklusD',
  },
  40039: {
    phone: '888-403-5690',
    gtag_code: 'hAxTCIOmp6cBELrklusD',
  }
}

export const SiteContext = React.createContext()

export const SiteContextProvider = props => {
  const [ state, setState ] = React.useState({
    ...metadata,
    isLoadingSiteData: true,
  })

  const geoInfo = getGeoFromLocalStorage()
  let categoryData = {}

  React.useEffect( async () => {
    const updateContext = geoInfo => {
      // Finally, update the context object.
      setState( prevState => ({
        ...prevState,
        ...metadata,
        // city: geoInfo ? geoInfo.city : 'Your Area',
        // zip_code: geoInfo ? geoInfo.zip_code : prevState.zip_code,
        isLoadingSiteData: false,
      }))
    }

    const fetchIVRPhoneNumber = (categoryData, geoInfo) => {
      // Hard-coded bypassing of PTM.

      // // Get the phone number.
      // fetch('https://twilioproxy.elocal.com/api/front_end/ivr_search', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     partner_campaign_ids: metadata.partner_campaign_ids,
      //     visit_data: {
      //       href: window.location.href,
      //       ...categoryData,
      //       session_id: getSessionId(),
      //       zip_code: geoInfo ? geoInfo.zip_code : metadata.zip_code,
      //     }
      //   })
      // })
      // .then( response => response.json() )
      // .then( response => {
      //   // Response is format { partner_campaign_is: phone_number }

        // console.debug('ptm response', JSON.stringify(response))

        // We'll need to find the matching partner_campaign_id and grab the number.
        let phone_number = metadata.phone
        let gtag_code = 'hAxTCIOmp6cBELrklusD'

        // Check to see if any of the partner_campaign_ids from metadata are in the response object (as a key).  If so, update the phone number.
        metadata.partner_campaign_ids.map( id => {
          if(PARTNER_CAMPAIGN_ID_MAPPINGS[id]){
            phone_number = PARTNER_CAMPAIGN_ID_MAPPINGS[id].phone
            gtag_code = PARTNER_CAMPAIGN_ID_MAPPINGS[id].gtag_code
          }
        })

        console.debug('default phone number', formatPhoneNumber(metadata.phone))
        console.debug('new phone number', formatPhoneNumber(phone_number))
        console.debug('gtag', gtag_code)
        
        metadata.phone = phone_number

        // We need to trigger the context update because gtag doesn't have an error callback.
        // Delay it a bit to give gtag time to finish.  This is HORRIBLE...
        setTimeout( () => {
          updateContext(geoInfo)
        }, 250);

        // Send the phone number up to GTC
        gtag('config', `AW-1030074938/${gtag_code || 'hAxTCIOmp6cBELrklusD'}`, {
          phone_conversion_number: phone_number,
          phone_conversion_callback: function(formatted_number) {
            console.debug('google phone number response', formatted_number)
            // In callback, update metadata.
            metadata.phone = formatPhoneNumber(formatted_number)
            updateContext(geoInfo)
          }
        })
      // })
      // .catch (err => {
      //   updateContext(geoInfo)
      // })
    }

    const fetchCategoryData = geoInfo => {
      // Get the category tracking data.
      fetch(`https://www.elocal.com/api/marketing/category/${metadata.categoryId}`)
        .then( response => response.json() )
        .then( categoryData => {
          setCategoryData(categoryData)
          fetchIVRPhoneNumber(categoryData, geoInfo)
        })
        .catch( err => {
          fetchIVRPhoneNumber({}, geoInfo)
        })
    }

    const fetchGeoInfo = () => {
      fetch('//ipwhois.pro/?key=J7OPppp50QNcRxNv')
        .then(response => response.json())
        .then(geoAPIData => {
          if(geoAPIData) {
            const location = (geoAPIData.city && geoAPIData.region_code ? `${geoAPIData.city}, ${geoAPIData.region_code}` : geoAPIData.city) || ''
            const geo = {
              location: location,
              city: geoAPIData.city,
              region_code: geoAPIData.region_code || '',
              zip_code: geoAPIData.postal,
              version: '2.0',
            }

            localStorage.setItem('geoInfo', JSON.stringify(geo))

            const metro = `${geoAPIData.city ? `${geoAPIData.city}` : ''}${geoAPIData.region_code ? `, ${geoAPIData.region_code}` : ''}`

            document.getElementById('root').setAttribute('data-city', metro)
            document.title = `${metadata.title}${metro ? `, ${metro}` : ''}`

            fetchCategoryData(geoAPIData)
          } else {
            fetchCategoryData()
          }
        })
        .catch( err => {
          fetchCategoryData()
        })
    }

    // Update metadata from qs.
    const partner_campign_id_override = new URLSearchParams(window.location.search).get('partner_campaign_id')
    metadata.partner_campaign_ids = partner_campign_id_override ? [ partner_campign_id_override.toString() ] : metadata.partner_campaign_ids || []

    if(geoInfo) {
      const metro = `${geoInfo.city ? `${geoInfo.city}` : ''}${geoInfo.region_code ? `, ${geoInfo.region_code}` : ''}`
      document.getElementById('root').setAttribute('data-city', metro)
      document.title = `${metadata.title}${metro ? `, ${metro}` : ''}`

      fetchCategoryData(geoInfo)
    } else {
      fetchGeoInfo()
    }
  }, [])

  return (
    <SiteContext.Provider value={[ state, setState ]}>
      {props.children}
    </SiteContext.Provider>
  )
}
