import React from 'react'

import {
    Redirect,
    Route,
    Switch,
} from 'react-router-dom'

import Automotive from 'routes/Automotive'
import Commercial from 'routes/Commercial'
import Landing from 'routes/Landing'
import NewHome from 'routes/NewHome'
import Residential from 'routes/Residential'

const App = () => {
  return (
    <Switch>
      <Route path="/automotive" render={props => <Automotive {...props} />}/>
      <Route path="/commercial" render={props => <Commercial {...props} />}/>
      <Route path="/new-home" render={props => <NewHome {...props} />}/>
      <Route path="/residential" render={props => <Residential {...props} />}/>
      <Route path="/" render={props => <Landing { ...props} />}/>

      <Redirect to="/" />
    </Switch>
  )
}

export default App
